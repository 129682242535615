<!-- Mto particular de APDS -->

<template>
  <div class="apd_M" v-if="schema">

      <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"       
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div>
                 
      <v-sheet :elevation="4" style="width:1070px">
        <div class="contenedor columna" style="padding-top:10px;">
          <div class="conflex">
            <!-- Botoneras -->
            <!-- Mto --> 
            <div style="justify-content:space-between;width:300px">           
              <btramto
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="m"
                  :estado="estado"
                  :nopesoShow=1
                  @onEvent="event_btra_Mto">        
              </btramto>
            </div>

            <div class="conflex" style="justify-content:space-between;width:80px">
              <!-- Ventana de Documentos -->
              <common_Docs
                :padre="stName"
                :id="record.id"
                :show="schema.btra.extra.docs"
                tip="12">
              </common_Docs>

              <!-- Ventana de Facturas -->
              <common_Fras
                :padre="stName"
                :id="record.id"
                :show="schema.btra.extra.fras"
                :accionRow="record"
                tip="12">
              </common_Fras>     
            </div>
          
            <!-- Extra -->            
            <div style="justify-content:space-between;width:100px">
              <btraextra
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="mextra"
                  @onEvent="event_btra_Extra">     
              </btraextra>
            </div>
          </div>

          <div class="contenedor conflex" style="padding-top:10px">          
            <div class="columna" style="width:55%"> 
              <div class="conflex" style="justify-content:space-between">
                <div class="cab ">IDENTIFICACIÓN</div>
                <div style="color:steelblue;padding-right:20px">
                  {{ schema.ctrls.id.value }}
                </div>
              </div> 

              <v-sheet v-bind="$cfg.styles.marco">            
                <div>
                  <v-switch
                    style="flex:1 1 15%;margin:0;padding:0"
                    true-value="1"
                    false-value="0"
                    color="green"
                    :readonly="!editable"
                    v-model="schema.ctrls.activo.value">
                  </v-switch>
                  <div style="margin-top:-10px;margin-right:10px">
                    Activo
                  </div>
                </div>           
                <div style="display:flex">
                  <v-text-field                
                    style="flex: 1 1 80%;font-weight: bold;"
                    v-bind="$input"
                    v-model="schema.ctrls.name.value"
                    :label="schema.ctrls.name.label"
                    :disabled="!editable">            
                  </v-text-field>              
                </div>

                <div style="display:flex">             
                  <v-text-field
                    style="flex: 1 1 40%"
                    v-bind="$input"
                    v-model="schema.ctrls.codigo.value"
                    :label="schema.ctrls.codigo.label"
                    :disabled="!editable">              
                  </v-text-field>

                  <uiText
                    v-bind="$input"
                    style="flex: 1 1 40%"
                    v-model="schema.ctrls.nif.value"
                    :label="schema.ctrls.nif.label"
                    :disabled="!editable"
                    format="cif"
                    @input="schema.ctrls.nif.value=$event">
                  </uiText>
                  
                  <v-select
                    style="flex: 1 1 20%"
                    v-bind="$select"
                    v-model="schema.ctrls.tarifa.value"
                    :label="schema.ctrls.tarifa.label"
                    :items="itemsTarifa"
                    :disabled="!editable"
                    item-value="id"
                    item-text="label">              
                  </v-select>
                </div>

                <div style="display:flex">             
                  <ctrlfinder
                    style="flex: 1 1 50%"
                    finderName="jefeszona_F" 
                    :schema="schema.ctrls.idjefe" 
                    :edicion="editable">
                  </ctrlfinder>
                  <ctrlfinder
                    style="flex: 1 1 50%"
                    finderName="agen_F" 
                    :schema="schema.ctrls.idage" 
                    :edicion="editable">
                  </ctrlfinder>
                </div>            

                <div style="display:flex">
                  <v-text-field
                    style="flex: 1 1 75%"
                    v-bind="$input"
                    v-model="schema.ctrls.empresa.value"
                    :label="schema.ctrls.empresa.label"
                    :disabled="!editable">            
                  </v-text-field>                  

                  <uiText
                    v-bind="$input"
                    style="flex: 1 1 25%"
                    v-model="schema.ctrls.cif.value"
                    :label="schema.ctrls.cif.label"
                    format="cif"
                    :disabled="!editable">                    
                    <!-- @input="schema.ctrls.cif.value=$event"> -->
                  </uiText>
                  
                </div>

                <div style="display:flex">
                  <v-text-field
                    style="flex: 1 1 75%"
                    v-bind="$input"
                    v-model="schema.ctrls.cont1.value"
                    :label="schema.ctrls.cont1.label"
                    :disabled="!editable">             
                  </v-text-field>

                  <uiText
                    v-bind="$input"
                    style="flex: 1 1 25%"
                    v-model="schema.ctrls.cont1_nif.value"
                    :label="schema.ctrls.cont1_nif.label"
                    :disabled="!editable"
                    format="cif"
                    @input="schema.ctrls.cont1_nif.value=$event">
                  </uiText>
                  
                </div>
              </v-sheet>

              <div style="display:flex">      
                <div class="columna">      
                  <div class="cab" style="width:120px">FOTO</div>          
                  <v-sheet width="150px" v-bind="$cfg.styles.marco"> 
                    <compImagen
                      :ctrl="schema.ctrls.foto"
                      :record="record"
                      :disabled="is_edit">
                    </compImagen>
                  </v-sheet>
                </div>

                <div class="columna">
                  <div class="conflex">
                    <div class="cab" style="width:150px">COD. POSTALES</div> 
                    <div class="cab" style="margin-left:50px;width:150px">RESTO CIAS</div>   
                  </div>   
                     
                  <v-sheet width="400px" v-bind="$cfg.styles.marco"> 
                    <div style="display:flex"> 
                      <div class="columna list_zonas" style="width:50%">   
                        <div class="conflex">
                          <v-btn 
                            icon 
                            @click="add_cpo(1)"
                            :disabled="estado=='nuevo'">
                              <v-icon >{{'mdi-map-marker-plus'}}</v-icon>
                          </v-btn>

                          <v-btn 
                            icon 
                            style="margin-left:20px"
                            @click="delete_cpo(1)"
                            :disabled="estado=='nuevo'">
                              <v-icon>{{'mdi-map-marker-minus'}}</v-icon>
                          </v-btn>

                          <v-btn 
                            v-if="itemsZonas.length"
                            style="margin-left:20px"
                            title="Copiar"
                            icon 
                            @click="copy(0)"
                            :disabled="estado=='nuevo'">
                              <v-icon >{{'mdi-content-copy'}}</v-icon>
                          </v-btn>
                        </div>

                        <v-list 
                          dense 
                          style="max-height:244px"
                          class="overflow-y-auto">
                          <template v-for="(item, index) in itemsZonas">
                          
                            <v-list-item
                              dense
                              :key="index">
                                <v-list-item-content>
                                  <v-list-item-title v-html="item.pob==''? '---' : item.pob"></v-list-item-title>
                                  <v-list-item-subtitle v-html="item.cod"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </div>

                      <div class="columna list_zonas" style="width:50%">             
                        <div class="conflex">
                          <v-btn 
                            icon 
                            @click="add_cpo(2)"
                            :disabled="estado=='nuevo'">
                              <v-icon >{{'mdi-map-marker-plus'}}</v-icon>
                          </v-btn>

                          <v-btn 
                            icon 
                            style="margin-left:20px"
                            @click="delete_cpo(2)"
                            :disabled="estado=='nuevo'">
                              <v-icon>{{'mdi-map-marker-minus'}}</v-icon>
                          </v-btn>

                          <v-btn 
                            v-if="itemsResto_zonas.length"
                            style="margin-left:20px"
                            title="Copiar"
                            icon 
                            @click="copy(1)"
                            :disabled="estado=='nuevo'">
                              <v-icon >{{'mdi-content-copy'}}</v-icon>
                          </v-btn>
                        </div>

                        <v-list 
                          dense 
                          style="max-height: 244px"
                          class="overflow-y-auto">
                          <template v-for="(item, index) in itemsResto_zonas">
                          
                            <v-list-item
                              dense
                              :key="index">
                                <v-list-item-content>
                                  <v-list-item-title v-html="item.pob==''? '---' : item.pob"></v-list-item-title>
                                  <v-list-item-subtitle v-html="item.cod"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </div>
                    </div>

                  </v-sheet>     
                </div>       
              </div>
            </div>

            <div class="columna" style="width:45%">
              <div class="cab">DATOS GENERALES</div>          
              <v-sheet v-bind="$cfg.styles.marco">  
                <div style="display:flex">          
                  <v-text-field
                    style="flex: 1 1 70%"
                    v-bind="$input"
                    v-model="schema.ctrls.dir.value"
                    :label="schema.ctrls.dir.label"
                    :disabled="!is_edit">            
                  </v-text-field>

                  <v-text-field
                    style="flex: 1 1 30%"
                    v-bind="$input"
                    v-model="schema.ctrls.cpo.value"
                    :label="schema.ctrls.cpo.label"
                    :disabled="!is_edit">              
                  </v-text-field>
                </div>

                <div style="display:flex">
                  <v-text-field
                    style="flex: 1 1 70%"
                    v-bind="$input"
                    v-model="schema.ctrls.pob.value"
                    :label="schema.ctrls.pob.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-text-field
                    style="flex: 1 1 30%"
                    v-bind="$input"
                    v-model="schema.ctrls.pro.value"
                    :label="schema.ctrls.pro.label"
                    :disabled="!is_edit">              
                  </v-text-field>
                </div>

                <div style="display:flex">
                  <v-text-field
                    style="flex: 1 1 27%"
                    v-bind="$input"
                    v-model="schema.ctrls.tlf1.value"
                    :label="schema.ctrls.tlf1.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-text-field
                    style="flex: 1 1 27%"
                    v-bind="$input"
                    v-model="schema.ctrls.mov1.value"
                    :label="schema.ctrls.mov1.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-text-field
                    style="flex: 1 1 27%"
                    v-bind="$input"
                    v-model="schema.ctrls.fax1.value"
                    :label="schema.ctrls.fax1.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-checkbox
                    style="flex: 1 1 19%"
                    v-bind="$checkbox"
                    v-model="schema.ctrls.agecom.value"
                    :label="schema.ctrls.agecom.label"
                    :disabled="!is_edit">              
                  </v-checkbox>
                </div>

                <div style="display:flex">
                  <v-text-field
                    style="flex: 1 1 27%"
                    v-bind="$input"
                    v-model="schema.ctrls.tlf2.value"
                    :label="schema.ctrls.tlf2.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-text-field
                    style="flex: 1 1 27%"
                    v-bind="$input"
                    v-model="schema.ctrls.mov2.value"
                    :label="schema.ctrls.mov2.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-text-field
                    style="flex: 1 1 27%"
                    v-bind="$input"
                    v-model="schema.ctrls.fax2.value"
                    :label="schema.ctrls.fax2.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-checkbox
                    style="flex: 1 1 19%"
                    v-bind="$checkbox"
                    v-model="schema.ctrls.agemf.value"
                    :label="schema.ctrls.agemf.label"
                    :disabled="!is_edit">              
                  </v-checkbox>
                </div>

                <div style="display:flex">
                  <v-text-field
                    style="flex: 1 1 81%"
                    v-bind="$input"
                    v-model="schema.ctrls.email1.value"
                    :label="schema.ctrls.email1.label"
                    :disabled="!is_edit">              
                  </v-text-field>

                  <v-checkbox
                    style="flex: 1 1 19%"
                    v-bind="$checkbox"
                    v-model="schema.ctrls.ageind.value"
                    :label="schema.ctrls.ageind.label"
                    :disabled="!is_edit">              
                  </v-checkbox>
                </div>
                
                <v-text-field
                  style="flex: 1 1 81%"
                  v-bind="$input"
                  v-model="schema.ctrls.email2.value"
                  :label="schema.ctrls.email2.label"
                  :disabled="!is_edit">
                </v-text-field>

                <ctrlobs3
                  :schemaComponente="schema.ctrls.obs"
                  :schema="schema"   
                  :record="record"
                  :edicion="is_edit">
                </ctrlobs3>                       
              </v-sheet>

              <div class="cab">OTROS DATOS</div>          
              <v-sheet v-bind="$cfg.styles.marco"> 
                <div style="display:flex">
                  <uiText
                    style="flex: 1 1 60%"
                    v-bind="$input"
                    v-model="schema.ctrls.banco.value"
                    :label="schema.ctrls.banco.label"
                    format="banco"
                    :disabled="!editable">              
                  </uiText>
                
                  <v-text-field
                    style="flex: 1 1 20%"
                    v-bind="$input"
                    v-model="schema.ctrls.iva.value"
                    :label="schema.ctrls.iva.label"                    
                    :disabled="!editable">              
                  </v-text-field>
                
                  <v-text-field
                    style="flex: 1 1 20%"
                    v-bind="$input"
                    v-model="schema.ctrls.irpf.value"
                    :label="schema.ctrls.irpf.label"
                    :disabled="!editable">              
                  </v-text-field>
                </div>

                <div style="display:flex">
                  <compfecha :schema="schema.ctrls.fhalta" :edicion="editable" />
                  <compfecha :schema="schema.ctrls.fhbaja" :edicion="editable" />
                  <compfecha :schema="schema.ctrls.fhnac" :edicion="editable" />
                </div>

                <div style="display:flex">
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.passwd.value"
                    :type="showpass1 ? 'text' : 'password'"
                    :append-icon="showpass1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="schema.ctrls.passwd.label"
                    :disabled="!is_edit"
                    @click:append="showpass1 = !showpass1">              
                  </v-text-field>

                  <compfecha :schema="schema.ctrls.acceso" :edicion="false" />

                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="schema.ctrls.web.value"
                    :label="schema.ctrls.web.label"
                    :disabled="!editable">              
                  </v-checkbox>
                </div>

                <div style="display:flex">                  
                  <v-menu top offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn 
                        v-on='on' :disabled="is_edit"
                        v-bind="$cfg.btn.busca_with_text">                    
                          Contrato <v-icon right dark>{{ "mdi-file-document-edit-outline" }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in items_contratos"
                        :key="index"
                        
                        @click="contratos(item.contrato)">                  
                          <v-list-item-title>{{ item.label }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <compfecha :schema="schema.ctrls.ctt_firma" :edicion="false" />                  
                  <div class="mt-2 mx-1">
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="schema.ctrls.ctt.value"
                      label="Firma"
                      :disabled="!editable || ndep<8 ">              
                    </v-checkbox>
                  </div>

                </div>
                 <div style="display:flex">                  
                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="schema.ctrls.ent_mat.value"
                    :label="schema.ctrls.ent_mat.label"
                    :disabled="true">   
                    <!-- :disabled="!is_edit">            -->
                  </v-checkbox>
                  <v-btn :disabled="true"
                    v-bind="$cfg.btn.busca_with_text">                    
                      Entrega Material <v-icon right dark>{{ "mdi-truck-delivery-outline" }}</v-icon>
                  </v-btn>
                </div>
              </v-sheet>
            </div>   
          </div>   
        </div>
      </v-sheet>
      
   

      <!-- Componentes dinámicos -->      
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado">
            <component                 
              :is="componente_dinamico"
              :padre="stName"
              :accion="accion_zona"
              :tipo="tipo"
              :accionRow="record"
              :record="record"
              :cps="cps"
              :apd="apd"
              componenteTipo="FC"
              @onEvent="event_dinamico"
              @update_zonas="update_zonas">
              <!-- @firma="update_zonas"> -->
              <!-- @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''" -->
            </component>           
          </div>
      </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const btraextra = () => plugs.groute("btraextra.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  const compImagen = () => plugs.groute("compImagen.vue", "comp");
  const common_Docs = () => plugs.groute("common_Docs.vue", "comp");
  const common_Fras = () => plugs.groute("common_Fras.vue", "comp");
  const apd_M_zonas = () => plugs.groute("apd_M_zonas.vue", "comp");

  const apds_contrato = () => plugs.groute("apds_contrato.vue", "comp");
       
  export default {
    mixins: [mixinMto],
    components: { 
      base_Header, 
      btramto, 
      btraextra, 
      ctrlfinder, 
      ctrlobs3, 
      compfecha, 
      compImagen,
      common_Docs, 
      common_Fras,
      apd_M_zonas,
      apds_contrato,

    },

    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      disparo: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'apd_M',
        stName:'stMapd',
              
        disparoFinder:false,       
        showpass1: false,    
        itemsTarifa: [],    
        items_contratos: [],
        itemsZonas: [],
        itemsResto_zonas: [],
        itemsCP:[],
        cps:"",        
        accion_zona:0,
        tipo:0,
        apd:0,
        componente_dinamico:null,
        modal:false
      };
    },

    computed: {
        editable() {
          return (this.is_edit && this.app==1);
        },
      
    },


    methods: {
      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Mantenimiento APDS"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo["standardM"]));
        this.Entorno.btra.footer.mto[1].view= false;
        console.log('BTERA: ', this.Entorno.btra);
              
        // items adicionales  
        // tarifas
        this.itemsTarifa= [
          { id: "1", label: "1" },
          { id: "2", label: "2" },
          { id: "3", label: "3" },
          { id: "4", label: "4" },
          { id: "5", label: "5" },
          { id: "6", label: "6" },
          { id: "7", label: "7 (Esp)" },
          { id: "8", label: "8 (A)" },
          { id: "9", label: "9 (A)" },
          { id: "10", label: "10 (A)" },
          { id: "11", label: "11 (A)" }
        ];

        // contratos
        this.items_contratos= [
          { id:"0", label: "Contrato Servicios", contrato: "servicios" },
          { id:"1", label: "Borrador Contrato", contrato: "contrato" },
          { id:"2", label: "Declaración Jurada", contrato: "declaracion" },
          { id:"3", label: "*FIRMA Contrato", contrato: "firma" }
        ]       
       
      },


      // acciones al cargar el record
      async record_cargado() {      
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'codpostales',
          fnt:'get',
          apd:this.accionRow.id,
          tp:0
        }};
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });          
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       
        // actualizo arrays de zonas
        this.itemsZonas= apiResult.r[0];
        this.itemsResto_zonas= apiResult.r[1];

        // guardo códigos postales separados por comas
        this.itemsCP= apiResult.r[2];
      },

      event_dinamico(evt) {
        if (evt.accion=='cerrar' || evt.accion==6) this.cerrar_dinamico();

        if (evt.accion=='evtFirma') {
          this.cerrar_dinamico();
          this.btra_Mto({ accion: 0 });

        }


      },

      evtFirma() {
        this.cerrar_dinamico();
        this.$emit('onEvent', { accion: 0 });
      },
      

      // muestro finder de facturas
      facturas() {
        this.dialog = true;
      },

      // oculto
      cerrar_fras() {
        this.dialog = false;
      },

      // contratos
      contratos(val) {
        
        if (val=='firma') this.contrato_firma();



      },
  
      contrato_firma() {
          
        this.componente_dinamico= "apds_contrato";   
        this.apd=this.accionRow.id;     
        //this.disparo= !this.disparo;
        this.modal=true;
      },

      // fn para  seleccionar la entrega de material a un APD
      entrega_material() {
        //alert("entrega material");
      },
      

      // ejecuto al terminar de guardar el Mto (override)
      async guardar_fin() {
        var foto= this.schema.ctrls.foto;

        // compruebo si hemos seleccionado una imagen para guardar
        if (!foto.comp.files) return;
  
        // monto argumentos para hacer la llamada a la API
        var args = { tipo:'fnc', accion: 'imagendb', fn_args: JSON.stringify({ accion:'set', tip:foto.comp.tip, tip_id:this.record.id })};
    
        // paso los argumentos a FORMDATA
        var formData = new FormData();
        for (const key in args) {
          formData.append(key, args[key]);
        }
      
        formData.append("imagen", foto.value=="-1"? "-1" : foto.comp.files);
        formData.append("token", sessionStorage.getItem("login") || null);
     
        // ejecuto llamada API
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: formData });          
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al guardar la imagen', 'error');
          return;
        }

        // actualizo ruta de la imagene en el control
        foto.value= this.$store.state.G.url_tmp + apiResult.r[0];
      },      

      // añado municipio a las zonas
      add_cpo(tipo) {
        this.modal= true;
        this.accion_zona= 1;
        this.tipo= tipo;
        this.componente_dinamico= 'apd_M_zonas';
      },


      // quito municipio de las zonas
      delete_cpo(tipo) {
        this.modal= true;
        this.accion_zona= 2;
        this.tipo= tipo;
        this.componente_dinamico= 'apd_M_zonas';
      },


      // copio códigos postales 
      copy(tipo) {
        this.cps=  this.itemsCP[tipo];      
      },


      // actualizo list de la zona recibida
      update_zonas(evt) {
        this.itemsCP[Number(evt.tipo) - 1]= evt.r[1][0];
        if (evt.tipo==1) {
          this.itemsZonas= evt.r[0];
          return;
        }

        this.itemsResto_zonas = evt.r[0];        
      },

      // cierro ventana de componente dinámico
      cerrar_dinamico() {
        this.modal= false;
        this.componente_dinamico= null;
      }

    }
  };
</script>


<style scoped>
  .list_zonas .v-list-item {
    padding:0px;
    margin:0px;
  }

  .list_zonas .v-list-item__content {
    padding: 0px 0px !important;
  }

  .list_zonas .v-list-item__title {
    font-size: 10px !important;
  }

  .list_zonas .v-list-item__subtitle {
    font-size: 10px !important;
  }
</style>